import React, { useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from 'framer-motion';

import config from './../config/config';
import { Row, Col } from 'react-grid-system';
import './AnimatedReview.scss';


function AnimatedReview(props) {

    let { itm, num } = props;

    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        visible: { scale: 1, opacity: 1 },
        hidden: { scale: 1.1, opacity: 0 }
    }
    const delayItem = num * .1;

    return (
        <div className='AnimatedReview'>
            <div className='comp-itm-3pt'>
                <Row className='comp-itm-inner'>
                    <Col md={12} sm={3} >
                        <motion.div
                            style={{ display: 'inline-block' }}
                            variants={variants}
                            ref={ref}
                            transition={{
                                delay: delayItem,
                                duration: .5,
                                type: 'tween'
                            }}
                            animate={controls}
                            initial='hidden'
                        >
                            <img src={`${config.apiServer}${itm.image.path}`} alt={itm.title} />
                        </motion.div>
                    </Col>
                    <Col md={12} sm={9}>
                        <h4>{itm.name}</h4>
                        <p className='title'>{itm.title}</p>
                        <p>{itm.description}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AnimatedReview;