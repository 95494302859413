import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';

import './Top.scss';
import backgroundImage from './../assets/top-img.jpg'
import { stripTags } from './../tools/tools';
import SubAnimation from './animation/SubAnimation'

function Top(props) {
    const data = useContext(UserContext);

    let { title, description } = data.pages.entries[props.pos];
    const titleList = title.split('|');
    description = stripTags(description);
    const firstTitle = titleList.shift();

    const goTo = (anchor) => {
        const url = window.location.href;               //Save down the URL without hash.
        window.location.href = "#" + anchor;                 //Go to the target element.
        window.history.replaceState(null, null, url);   //Don't like hashes. Changing it back.
    }

    return (
        <section className='section scnTop' style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3) ), url(${backgroundImage})`
        }} >
            <h3>
                {firstTitle || ''}
                {titleList &&
                    <SubAnimation text={titleList} />
                }
            </h3>
            <p>{description}</p>
            <div className='links'>
                <button className='bookingLink' onClick={() => goTo('booking')} ><span>Booking & Kontakt</span></button>
                <button className='priceLink' onClick={() => goTo('price')} ><span>Priser</span></button>
            </div>
        </section>
    )
}

export default Top;
