import React, { useState, useEffect } from 'react'

import { useTransition, animated, config } from '@react-spring/web'
import styles from './styles.module.scss'

const slides = [
  'STEMME',
  'KÆBE',
  'FYSIO',
]

function SubAnimation() {
  const [index, set] = useState(0);
  const [running, setRunning] = useState(true);
  // const blackRectAnimationRef = useRef(null);

  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0, transform: 'scale(0.8) translateY(0px)', transformOrigin: 'center' },
    enter: () => async (next, cancel) => {
      await next({ opacity: 1, transform: 'scale(1) translateY(6px)' }) // transform: "translateY(15px)"
    },
    leave: { opacity: 0.2, delay: 400 },
    config: { duration: 1200, ...config.default },
    //ref: blackRectAnimationRef,
    onRest: (_a, _b, item) => {
      if (index === item && running === true) {
        set(state => (state + 1) % slides.length)
      }
    },
    exitBeforeEnter: true,
  })

  useEffect(() => {
    setRunning(true)
    return () => {
      setRunning(false)
    }

  }, [])

  return (
    <div className="flex fill center">
      {transitions((style, i) => (
        <animated.div
          className={styles.animated}
          style={{
            ...style,
          }}
        >
          {slides[i]}
        </animated.div>
      ))}
    </div>
  )
}



//////////////////////////////////////////////////////////////////////


// function SubAnimation(props) {

//     const [pos, setPos] = useState(0)
//     const { text = [] } = props;
//     let out = text[pos]

//     const changeText = () => {
//         return new Promise(resolve => {
//             let nextPos = pos + 1
//             if (nextPos === text.length) nextPos = 0;
//             setPos(nextPos)
//             resolve();
//         });
//     }


//     const Content = useSpring(async next => {
//         while (true) {
//             await next({
//                 from: { opacity: 0, transform: 'rotateX(90deg)' },
//                 opacity: 1,
//                 transform: 'rotateX(0deg)',
//                 config: { duration: 600 }
//             })
//             await delay(5000)
//             await next({
//                 opacity: 0,
//                 transform: 'rotateX(90deg)',
//                 scale: 2,
//                 config: { duration: 600 }
//                 //config: options
//             })
//             await delay(500)
//             await changeText()
//         }
//     })

//     const baseStyles = {
//         //fontSize: '56px',
//     }

//     return <Content >
//         {props => (
//             <Spring style={{ ...props }} >
//                 <span style={baseStyles}>{out}</span>
//             </Spring>
//         )}
//     </Content>
// }

export default SubAnimation
