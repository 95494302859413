import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";

import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import config from './config/config';
import './App.scss';
import { UserProvider } from './tools/UserContex'
import TopBar from './components/TopBar';
import Frontpage from './pages/Frontpage';
import Behandling from './pages/Behandling';
import OmMig from './pages/OmMig';

const API = `${config.apiServer}/api/collections/get/sections`;
const META = `${config.apiServer}/api/singletons/get/site`;
function App() {

	const [data, setData] = useState(null);
	const [meta, setMeta] = useState(null);

	useEffect(() => {
		const fetchOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: queryString.stringify({
				populate: '1',
				populate_module: '2'
			}),
		};
		async function fetchData() {

			const resMeta = await fetch(META, fetchOptions);
			const resMetaData = await resMeta.json();
			setMeta(resMetaData);

			const res = await fetch(API, fetchOptions);
			const resData = await res.json();
			setData(resData);
		}

		fetchData();
	}, []);

	if (!data || !meta) return false

	return (
		<div className="App">
			{/* <h1> Stemme kæbe fysio</h1> */}
			<Helmet>
				<meta charSet="utf-8" />
				<title>{meta.title}</title>
				<link rel="canonical" href="https://stemmekæbefysio.dk" />
				<meta name="description" content={meta.description}></meta>
				<meta name="keywords" content={meta.keywords}></meta>
				<meta name="robots" content="index, follow"></meta>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta data-rh="true" property="og:url" content="https://stemmekæbefysio.dk" />
				<meta data-rh="true" property="og:title" content={meta.title} />
				<meta data-rh="true" property="og:description" content={meta.description}></meta>
				<meta data-rh="true" property="og:type" content="website" />
				<meta data-rh="true" property="og:site_name" content="Stemmekæbefysio" />
				<meta data-rh="true" property="og:image" content={'https://stemmekæbefysio.dk' + meta.image.path} />
			</Helmet>
			<TopBar />
			<UserProvider value={{ pages: data }}>
				<Routes>
					<Route path="/behandling/:page" element={<Behandling />} />
					<Route path="/om-mig/:page" element={<OmMig />} />
					<Route path="/" element={<Frontpage />} />
				</Routes>
			</UserProvider>

		</div>
	);
}

export default App;
