import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import raf from 'raf';


function ScrollInNav(props) {

    const fixedStyle = {
        position: 'fixed',
        // transition: 'all 1.2s ease-in-out',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 5px 10px 0 rgba(50, 50, 50, 0.08)',

    };

    const hiddenStyle = {
        // WebkitTransform: 'translateY(-50%)',
        // MsTransform: 'translateY(-50%)',
        // transform: 'translateY(-50%)'
    };

    const scrolledInStyle = {
        transform: 'translateY(0)'
    };

    const [hidden, setHidden] = useState(true);
    const ref = useRef();
    let handlingScrollUpdate = false;

    const getScrollY = () => {
        if (window.pageYOffset !== undefined) {
            return window.pageYOffset
        } else if (window.scrollTop !== undefined) {
            return window.scrollTop
        } else {
            return (document.documentElement || document.body.parentNode || document.body).scrollTop
        }
    }

    const handleScroll = () => {
        if (!handlingScrollUpdate) {
            handlingScrollUpdate = true;
            raf(update);
        }
    }

    const update = () => {
        let currentScrollY = getScrollY();
        setHidden(currentScrollY < props.scrollInHeight)
        handlingScrollUpdate = false;
    }

    const addScrollListner = () => {
        window.addEventListener('scroll', handleScroll);
    }

    const removeScrollListner = () => {
        window.removeEventListener('scroll', handleScroll);
    }

    useEffect(() => {
        addScrollListner();
        return () => {
            removeScrollListner();
        }
    });

    let renderStyle = fixedStyle;
    renderStyle = hidden ?
        { ...renderStyle, ...scrolledInStyle } :
        { ...renderStyle, ...hiddenStyle };

    const renderClass = hidden ? 'navigation-render-normal' : 'navigation-render-small'

    return (
        <div className={`scroll-in-nav ${renderClass}`} ref={ref} style={renderStyle}>
            {props.children}
        </div>
    )
}

ScrollInNav.propTypes = {
    scrollInHeight: PropTypes.number
};
ScrollInNav.defaultProps = {
    scrollInHeight: 30
};

export default ScrollInNav;
