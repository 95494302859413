import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';
import { insertHTML } from './../tools/tools';
// import config from './../config/config';
import './Grants.scss';

function Grants(props) {

    const data = useContext(UserContext);
    let { title, description } = data.pages.entries[props.pos];

    return (
        <section className='section scnGrants' >
            <div className='section-container'>
                <h3 className='section-title'>{title}</h3>
                {
                    insertHTML(description, 'content')
                }

            </div>
        </section>

    )
}

export default Grants;
