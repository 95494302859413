import React, { useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from 'framer-motion';
import {
    useNavigate
} from "react-router-dom";

import { slugify } from '../tools/tools';
import config from '../config/config';
import './AnimatedTeaser.scss'

const AnimatedTreatment = (props) => {
    const { num, itm } = props;
    const navigate = useNavigate();

    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: .2,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
    }

    const delayItem = num * .1

    return (
        <motion.div key={itm._id} className='AnimatedTreatment'
            variants={variants}
            ref={ref}
            transition={{
                delay: delayItem,
                duration: .5,
                type: 'tween'
            }}
            animate={controls}
            initial='hidden'
        >
            <img src={`${config.apiServer}${itm.image.path}`} alt={itm.title} />
            <h4>{itm.title}</h4>
            <p>{itm.teaser}</p>
            <button className='link' onClick={() => navigate(`/behandling/${slugify(itm.title)}`)}>Læs mere</button>
        </motion.div>
    )
}

export default AnimatedTreatment;
