import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';
import { Container, Row, Col } from 'react-grid-system';
import { insertHTML } from './../tools/tools';
import './Booking.scss';

function Booking(props) {
    const data = useContext(UserContext);
    let { title, description } = data.pages.entries[props.pos];

    return (

        <section className='section scnBooking' >

            <Container className='section-container' id='booking'>

                <Row>
                    <Col xs={12} >
                        <h3 className='section-title'>{title}</h3>
                    </Col>
                    <Col md={6} sm={12} className='section-booking'>
                        <iframe
                            title='Booking'
                            src='https://system.easypractice.net/book/stemme-kaebe-fysio'
                            width='100%'
                            height='1000'
                            style={{ border: 'none' }}
                        ></iframe>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className='section-subtitle'>
                            {
                                insertHTML(description, 'content')
                            }
                        </div>
                    </Col>

                </Row>

            </Container>
        </section>

    )
}

export default Booking;