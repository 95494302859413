import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';

import { Container, Row, Col, setConfiguration } from 'react-grid-system';
import AnimatedReview from './../components/AnimatedReview'
import './Review.scss';

setConfiguration({ gutterWidth: 0 });

function Reviews(props) {

    const data = useContext(UserContext);
    let { title, items } = data.pages.entries[props.pos];

    const elm = items.map((itm, num) => {
        return <Col key={itm._id} sm={12} md={4} className='comp-itm-outer'>
            <AnimatedReview itm={itm} num={num} />
        </Col>
    });

    return (
        <section className='section scnReviews' >
            <Container className='section-container'>
                <h3 className='section-title'>{title}</h3>
                <Row className='comp-content'>
                    {elm}
                </Row>

            </Container>
        </section>
    )
}

export default Reviews;