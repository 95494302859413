import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';
import './Footer.scss';
import { stripTags } from './../tools/tools';

function Footer(props) {
    const data = useContext(UserContext);
    let { title } = data.pages.entries[props.pos];
    title = stripTags(title);

    return (
        <div className='footer'>
            <p>{title}</p>
        </div>
    )
}

export default Footer;

