import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';

import Treatment from './../sections/Treatment'
import Price from './../sections/Price'
import Grants from './../sections/Grants'
import AboutMe from './../sections/AboutMe'
import Review from './../sections/Review'
import Map from './../sections/Map'
import Booking from './../sections/Booking'
import Footer from './../sections/Footer'
import Top from './../sections/Top'

function FrontPage() {
    const data = useContext(UserContext)
    const { entries } = data.pages;

    if (!entries) return null;

    const sections = entries.map((itm, pos) => {

        switch (itm.id) {
            case 'treatment':
                return <Treatment key={itm.id} pos={pos} />
            case 'price':
                return <Price key={itm.id} pos={pos} />
            case 'grants':
                return <Grants key={itm.id} pos={pos} />
            case 'about-me':
                return <AboutMe key={itm.id} pos={pos} />
            case 'review':
                return <Review key={itm.id} pos={pos} />
            case 'booking':
                return <Booking key={itm.id} pos={pos} />
            case 'footer':
                return <Footer key={itm.id} pos={pos} />
            case 'top':
                return <Top key={itm.id} pos={pos} />
            case 'map':
                return <Map key={itm.id} pos={pos} />
            default:
                return ''
        }

    })

    return (
        <div >
            {sections}
        </div>
    )
}

export default FrontPage;
