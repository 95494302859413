import React from 'react';
import ScrollNav from './ScrollNav';

import logo from './../assets/fys_logo.gif';
import './TopBar.scss'

function TopBar(props) {
  return (
    <ScrollNav scrollInHeight={100}>
      <div className='topBar'>
        <div className='logoElm'>
          <img src={logo} alt='Logo' />
        </div>

        <div className='textElm'>
          <h3>
            STEMME | KÆBE | FYSIO
          </h3>
          <p>v/ fysioterapeut Mogens Eggemann</p>
        </div>
      </div>
    </ScrollNav>
  );
}

export default TopBar;
