import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';
import AnimatedTreatment from './../components/AnimatedTreatment';

import { stripTags } from './../tools/tools';
import './Treatment.scss';

function Treatment(props) {

    const data = useContext(UserContext);
    // const [readmore, setReadmore] = useState(null);
    let { title, description, items } = data.pages.entries[props.pos];
    description = stripTags(description);

    const elm = items.map((itm, num) => {
        return <AnimatedTreatment itm={itm} num={num} key={num} />
    });

    return (
        <section className='section scnTreatment' >
            <div className='section-container'>
                <h3 className='section-title'>{title}</h3>
                <p className='section-subtitle'>{description}</p>
                <div className='comp-content' >
                    {elm}
                </div>
                {/* {readmore !== null &&
                    <div className='comp-content-readmore'>
                        {
                            insertHTML(readmore, 'content')
                        }
                    </div>
                } */}
            </div>
        </section>
    )
};

export default Treatment;
