import React, { useEffect, useContext } from 'react';
import UserContext from './../tools/UserContex';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from 'framer-motion';
import './Price.scss';
import backgroundImage from './../assets/bg_port.jpg';
import CountUp from 'react-countup';

function Price(props) {
    const data = useContext(UserContext);
    let { title, items } = data.pages.entries[props.pos];

    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });



    useEffect(() => {

        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        visible: { scale: 1, opacity: 1 },
        hidden: { scale: 2, opacity: 0 }
    }

    const elm = items.map((itm, num) => {
        const delayItem = num * .2
        return <div key={itm._id} className='comp-itm'>
            <p>
                <motion.span
                    style={{ display: 'inline-block' }}
                    variants={variants}
                    ref={ref}
                    transition={{
                        delay: delayItem,
                        duration: .5,
                        type: 'tween'
                    }}
                    animate={controls}
                    initial='hidden'
                >
                    {inView === true &&
                        < CountUp
                            start={Number(itm.pris) - 100}
                            end={Number(itm.pris)}
                        />
                    }
                </motion.span></p>
            <h4>{itm.title}</h4>
        </div>
    });

    return (

        <section className='section scnPrice' style={{ backgroundImage: `url(${backgroundImage})` }} id='price'>
            <div className='section-container'>
                <h3 className='section-title'>{title}</h3>
                <div className='comp-content'>
                    {elm}
                </div>
            </div>
        </section>

    )
}

export default Price;
