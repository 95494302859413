import React, { useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from 'framer-motion';
import {
    useNavigate
} from "react-router-dom";
import { slugify } from '../tools/tools';
import './AnimatedAbout.scss';

function AnimatedAbout(props) {

    let { itm, num = 0 } = props;
    const navigate = useNavigate();

    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 100 }
    }

    const delayItem = num * .1;

    return (
        <motion.div className='AboutMe'
            variants={variants}
            ref={ref}
            transition={{
                delay: delayItem,
                duration: .5,
                type: 'tween'
            }}
            animate={controls}
            initial='hidden'
        >
            <h4>{itm.title}</h4>
            <p>{itm.teaser}</p>
            <button className='link' onClick={() => navigate(`/om-mig/${slugify(itm.title)}`)}>Læs mere</button>
        </motion.div>
    )
}

export default AnimatedAbout;
