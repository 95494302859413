
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import './Map.scss';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function MapSection(props) {
    const tileRef = useRef();
    const position = [55.66225206245454, 12.576618220907328]
    const [map, setMap] = useState(null);
    const [filter,] = useState(80);

    useEffect(() => {
        if (map) {
            tileRef.current
                .getContainer()
                .style.setProperty("filter", `brightness(${filter}%)`);
        }
    }, [map, filter]);

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <MapContainer
                center={position}
                zoom={14}
                style={{ height: '400px' }}
                whenReady={setMap}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                attributionControl={false}
                zoomControl={false}
            >
                <Marker position={position}>
                    <Popup>
                        Havnestadsklinikken, <br />Kigkurren 6-8, 2300 København S<br />
                        <a href='https://goo.gl/maps/Av3yQqSH4KTwpv897'
                            rel='noreferrer'
                            target='_blank'>Find vej</a>
                    </Popup>
                </Marker>
                <TileLayer
                    ref={tileRef}

                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        </div >

    )
}

export default MapSection;


