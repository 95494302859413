import React from 'react';
import App from './App';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');
const FullPage = () => <BrowserRouter><App /></BrowserRouter>

if (rootElement.hasChildNodes()) {
  hydrate(<FullPage />, rootElement);
} else {
  render(<FullPage />, rootElement);
}
