import React, { useContext } from 'react';
import UserContext from './../tools/UserContex';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';
import AnimatedAbout from '../components/AnimatedAbout'
import './AboutMe.scss';
import backgroundImage from './../assets/about.jpg';

setConfiguration({ gutterWidth: 0 });

function AboutMe(props) {

	const data = useContext(UserContext);
	let { title, items } = data.pages.entries[props.pos];

	const elm = items.map((itm, num) => {
		return <Col key={itm._id} sm={12} md={4}>
			<AnimatedAbout itm={itm} num={num} />
		</Col>


	});

	return (
		<section className='section scnAbout' style={{ backgroundImage: `url(${backgroundImage})` }}>
			<Container className='section-container'>
				<h3 className='section-title'>{title}</h3>
				<Row className='comp-content'>
					{elm}
				</Row>
			</Container>
		</section>


	)
}

export default AboutMe;
