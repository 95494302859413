import React, { useContext, useEffect } from 'react';
import UserContext from '../tools/UserContex';
import { slugify, insertHTML } from '../tools/tools';
import {
  useParams
} from "react-router-dom";
import './OmMig.scss'

import Footer from '../sections/Footer'
import BackLink from '../components/BackLink';

function OmMig() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = useContext(UserContext)
  const { page } = useParams();

  const { entries } = data.pages;
  if (!entries) return null;

  const sections = entries.map((itm, pos) => {
    switch (itm.id) {
      case 'footer':
        return <Footer key={itm.id} pos={pos} />
      default:
        return ''
    }
  })

  const treatment = entries.filter((itm) => {
    return itm.id === 'about-me'
  });

  const treatmentContent = treatment[0].items.filter((itm) => {
    return slugify(itm.title) === page
  })

  if (!treatmentContent || treatmentContent.length === 0) {
    return <div style={{ marginTop: '300px' }}><h1>Siden findes ikke (404)</h1></div>
  }
  const { title, description } = treatmentContent[0];

  return (
    <div >

      <section className='omMig'>
        <div className='sectionHeader'>
          <div className='sectionContainer'>
            <BackLink />
            <h1>{title}</h1>
          </div>
        </div>
        <div className='sectionArticle'>
          {/* style={{ backgroundImage: `url(${backgroundImage})` }} */}

          <div className='sectionContainer articleBody'>

            {
              insertHTML(description, 'content')
            }

          </div>
        </div>
      </section>
      {sections}
    </div>
  )
}

export default OmMig;
